const Swiper = require('swiper');
const endorsementsBlocks = $('.homepage-endorsement-block');
const isMobile = $(document).width() < 768;

/**
* Resizes the last odd slide card in the endorsements block to match the width of the second last card
*/
function resizeLastOddSlide() {
    for (var j = 0; j < endorsementsBlocks.length; j++) {
        const endorsementBlock = $(endorsementsBlocks[j]);
        const slideCards = endorsementBlock.find('.experience-commerce_assets-homepageEndorsementBlockCard');
        
        if (!isMobile && slideCards.length > 3 && slideCards.length % 3 !== 0) {
            $(slideCards[slideCards.length - 1]).find('.homepage-endorsement-block-card').css('max-width', $(slideCards[slideCards.length - 3]).find('.homepage-endorsement-block-card').outerWidth() + 'px');
        }
    }
}

$(document).ready(function () {
    for (var i = 0; i < endorsementsBlocks.length; i++) {
        const endorsementBlock = $(endorsementsBlocks[i]);
        const slideCards = endorsementBlock.find('.experience-commerce_assets-homepageEndorsementBlockCard');

        if ((isMobile && slideCards.length <= 1) || (!isMobile && slideCards.length <= 3)) {
            endorsementBlock.find('.homepage-endorsement-block-wrapper').addClass('px-lg-0 px-4');
            endorsementBlock.find('.homepage-endorsement-block-card').addClass('limit-width');

            if (!isMobile) {
                endorsementBlock.find('.swiper-wrapper').addClass('with-gap');
            }
        } else {
            if (isMobile) {
                slideCards.addClass('swiper-slide');
            } else {
                for (let i = 0; i < slideCards.length; i += 3) {
                    const pair = slideCards.slice(i, i + 3);
                    pair.wrapAll('<div class="swiper-slide d-flex swiper-homepage-endorsement-block-desktop"></div>');
                }
            }
        }
    }

    if ($('.swiper-homepage-endorsement-block').length > 0) {
        // eslint-disable-next-line no-unused-vars
        var endorsementsSlider = new Swiper('.swiper-homepage-endorsement-block', {
            zoom: false,
            slidesPerView: 1,
            loop: false,
            centeredSlides: false,
            keyboardControl: false,
            grabCursor: true,
            spaceBetween: 16,
            pagination: '.swiper-pagination-endorsement-block',
            breakpoints: {
                584: {
                    slidesPerView: 1,
                    loop: true
                },
                768: {
                    slidesPerView: 1,
                    loop: false
                },
                1024: {
                    slidesPerView: 1,
                    loop: false
                }
            }
        });

        resizeLastOddSlide();
    }

    $(window).on('resize', function () {
        resizeLastOddSlide();
    });
});
